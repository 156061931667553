// src/components/Education.js
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Grid,
  Avatar,
} from '@mui/material';
import { School as SchoolIcon } from '@mui/icons-material';

import GeorgiaTechLogo from '../assets/logos/georgia_tech_logo.jpg';
import OhioWesleyanLogo from '../assets/logos/ohio_wesleyan_logo.jpg';

function Education() {
  const theme = useTheme();

  const education = [
    {
      degree: 'Master of Science',
      specialization: 'Computer Science, Machine Learning',
      institution: 'Georgia Institute of Technology',
      duration: 'January 2024 – July 2025',
      details:
        'Courses: Operating Systems, Machine Learning, Reinforcement Learning, Machine Learning for Trading, AI Ethics',
      icon: <SchoolIcon />,
      logo: GeorgiaTechLogo,
      color: theme.palette.customColors.georgiaTech,
    },
    {
      degree: 'Bachelor of Arts',
      specialization: 'Computer Science and Mathematics',
      institution: 'Ohio Wesleyan University',
      duration: 'August 2019 – May 2023',
      details:
        'Activities and societies: Phi Gamma Delta, Chess Club, Ultimate Frisbee, and Intramural Soccer.',
      icon: <SchoolIcon />,
      logo: OhioWesleyanLogo,
      color: theme.palette.customColors.ohioWesleyan,
    },
  ];

  return (
    <Box
      id="education"
      sx={{
        py: 8,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="md">
      <Typography
          variant="h4"
          gutterBottom
          align="left"
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          Education
        </Typography>
        <Grid container spacing={4}>
          {education.map((edu, index) => (
            <Grid item xs={12} key={index}>
              <Card
                elevation={4}
                sx={{
                  transition: 'transform 0.3s',
                  '&:hover': { transform: 'scale(1.02)' },
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <CardContent>
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} sm="auto">
                      <Avatar
                        src={edu.logo}
                        alt={`${edu.institution} Logo`}
                        sx={{ width: { xs: 40, md: 60 }, height: { xs: 40, md: 60 } }}
                        variant="square"
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', md: '1.5rem' } }}
                      >
                        {edu.degree}
                      </Typography>

                      {edu.specialization && (
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{ mt: 0.5, fontStyle: 'italic', fontSize: { xs: '0.9rem', md: '1rem' } }}
                        >
                          {edu.specialization}
                        </Typography>
                      )}

                      <Typography
                        variant="body1"
                        color="primary.main"
                        sx={{ mt: 0.5, fontSize: { xs: '1rem', md: '1.2rem' } }}
                      >
                        {edu.institution}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ mt: 1.5, lineHeight: 1.6, fontSize: { xs: '0.8rem', md: '1rem' } }}
                      >
                        {edu.details}
                      </Typography>

                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: 1, fontSize: { xs: '0.8rem', md: '0.9rem' } }}
                      >
                        {edu.duration}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Education;

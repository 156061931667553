import { Box } from "@mui/material";
import React from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Education from "../components/Education";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import Splash from "../components/Splash";

function Home({ isDesktop, isSidebarOpen, isMobileView, drawerWidth, theme }) {
  return (
    <Box
      id="home"
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        color: "text.primary",
        p: 3,
        mt: "64px", // Height of the Header
        // Adjust margin-left: no shift when in Mobile View
        ml:
          isDesktop && isSidebarOpen && !isMobileView ? `${drawerWidth}px` : 0,
        transition: theme.transitions.create(["padding"], {
          easing: theme.transitions.easing.easeInOut,
          duration: 500,
        }),
        // If in Mobile View, ensure full width within the constrained container
        ...(isMobileView && {
          width: "100%",
          maxWidth: "375px",
        }),
      }}
    >
      <Splash isDesktop={isDesktop} isMobileView={isMobileView} />
      <About />
      <Experience />
      <Education />
      <Projects />
      <Contact />
    </Box>
  );
}

export default Home;

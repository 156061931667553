// src/components/Footer.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Footer() {
  const theme = useTheme();

  return (
    <Box
      id="footer"
      component="footer"
      sx={{
        height: "64px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        bgcolor: "background.paper",
        borderTop: `1px solid ${theme.palette.border.main}`,
        width: "100%",
        mt: "auto",
        px: 0, // Remove padding if needed
      }}
    >
      <Typography variant="body2" sx={{ mx: 0 }}>
        &copy; {new Date().getFullYear()} Elijah Adams. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;

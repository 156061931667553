// src/GlobalStyles.js
import React from 'react';
import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

function GlobalStylesComponent() {
  return (
    <MuiGlobalStyles
      styles={{
        body: { margin: 0, padding: 0, boxSizing: 'border-box' },
        a: { textDecoration: 'none', color: 'inherit' },
        '*::selection': {
          backgroundColor: 'primary.main',
          color: 'white',
        },
        // Add more global styles as needed
      }}
    />
  );
}

export default GlobalStylesComponent;

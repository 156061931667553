import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

function Papers() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");

  const papers = [
    {
      title: "Overcooked",
      description:
        "Paper on implementing and training multi-agent reinforcement learning agents with MAPPO in the Overcooked environment.",
      image: "/images/overcooked_first_page.png",
      document: "/documents/overcooked.pdf",
      keywords: [
        "Multi-agent RL",
        "MAPPO",
        "Game AI",
        "Collaborative Agents",
        "Reinforcement Learning",
      ],
    },
    {
      title: "Lunar Lander",
      description:
        "Study on implementing a reinforcement learning agent using the REINFORCE algorithm for the LunarLanderContinuous-v2 environment.",
      image: "/images/lunar_lander_first_page.png",
      document: "/documents/lunar_lander.pdf",
      keywords: [
        "REINFORCE",
        "RL",
        "OpenAI Gym",
        "Policy Gradient",
        "Continuous Control",
        "Lunar Landing",
      ],
    },
    {
      title: "Exploring Reinforcement Learning for MDPs",
      description:
        "Research paper comparing the performance of Policy Iteration, Value Iteration, Q-Learning, and SARSA in traffic flow and robot navigation Markov Decision Processes.",
      image: "/images/rl_mdp_preview.jpg",
      document: "/documents/rl_mdp_paper.pdf",
      keywords: [
        "Reinforcement Learning",
        "MDP",
        "Q-Learning",
        "SARSA",
        "Policy Iteration",
        "Value Iteration",
        "Traffic Control",
        "Warehouse Robots",
      ],
    },
    {
      title: "Exploring Supervised Learning Techniques",
      description:
        "Research paper on supervised learning machine learning algorithms.",
      image: "/images/supervised_learning_preview.jpg",
      document: "/documents/supervised_learning_paper.pdf",
      keywords: [
        "Supervised Learning",
        "Machine Learning",
        "Classification",
        "SVM",
        "k-Nearest Neighbor",
        "Neural Networks",
      ],
    },
    {
      title: "Exploring Randomized Optimization",
      description:
        "Paper on techniques in randomized optimization, analyzing algorithms like Randomized Hill Climbing, Simulated Annealing, Genetic Algorithm, and MIMIC.",
      image: "/images/exploring_randomized_optimization_first_page.png",
      document: "/documents/exploring_randomized_optimization.pdf",
      keywords: [
        "Optimization",
        "Genetic Algorithm",
        "Simulated Annealing",
        "Randomized Hill Climbing",
        "MIMIC",
        "Backpropagation",
      ],
    },
    {
      title: "Unsupervised Learning and Dimensionality Reduction",
      description:
        "Research on unsupervised learning techniques and dimensionality reduction, including clustering and feature reduction applied to wine and abalone datasets.",
      image:
        "/images/unsupervised_learning_and_dimensionality_reduction_first_page.png",
      document:
        "/documents/unsupervised_learning_and_dimensionality_reduction.pdf",
      keywords: [
        "Unsupervised Learning",
        "Clustering",
        "PCA",
        "ICA",
        "t-SNE",
        "K-Means",
        "EM Algorithm",
      ],
    },
    {
      title: "Assess Learners",
      description:
        "Paper on implementing and evaluating four supervised learning algorithms: Decision Tree, Random Tree, Bag Learner, Insane Learner.",
      image: "/images/assess_learners_first_page.png",
      document: "/documents/assess_learners.pdf",
      keywords: [
        "Decision Trees",
        "Random Forest",
        "Ensemble Learning",
        "Bagging",
        "Regression Trees",
        "Machine Learning for Trading",
      ],
    },
    {
      title: "Indicator Evaluation",
      description:
        "Paper developing technical indicators for stock market strategies, with benchmarks using a Theoretically Optimal Strategy.",
      image: "/images/indicator_evaluation_first_page.png",
      document: "/documents/indicator_evaluation.pdf",
      keywords: [
        "Technical Analysis",
        "Stock Market",
        "Trading",
        "Simple Moving Average",
        "Bollinger Bands",
        "Momentum",
      ],
    },
    {
      title: "Strategy Evaluation",
      description:
        "Paper on implementing and benchmarking stock market trading strategies, including a manual strategy and a Q-Learner-based strategy.",
      image: "/images/strategy_evaluation_first_page.png",
      document: "/documents/strategy_evaluation.pdf",
      keywords: [
        "Q-Learning",
        "Trading",
        "Strategy",
        "Reinforcement Learning",
        "Algorithmic Trading",
        "Market Impact",
      ],
    },
  ];

  return (
    <Box id="papers" sx={{ py: 5 }}>
      <Container>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Research
        </Typography>

        <Grid container spacing={4}>
          {papers.map((paper, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  position: "relative",
                  height: "100%",
                  borderRadius: 2,
                  pb: 4,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  boxShadow: 3,
                  "&:hover": {
                    boxShadow: 6,
                    transform: "scale(1.02)",
                  },
                }}
              >
                {paper.image && (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(0, 0, 0, 0.2)"
                            : "",
                      }}
                    />
                    <CardMedia
                      component="img"
                      image={paper.image}
                      alt={`${paper.title} preview`}
                      sx={{
                        width: "100%",
                        maxHeight: "600px", // Maximum height constraint
                        objectFit: "contain",
                        objectPosition: "center",
                        display: "block",
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                      }}
                    />
                  </Box>
                )}
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {paper.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 3 }} // Increased bottom margin
                  >
                    {paper.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      mt: "auto", // Push to bottom of container
                    }}
                  >
                    {paper.keywords.map((keyword, idx) => (
                      <Chip
                        key={idx}
                        label={keyword}
                        size="small"
                        sx={{
                          backgroundColor: theme.palette.primary.main + "20",
                          color: theme.palette.primary.main,
                          borderRadius: "16px",
                          fontWeight: "500",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main + "30",
                          },
                        }}
                      />
                    ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      left: 12,
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Button
                      size="small"
                      color="info"
                      onClick={() => {
                        setCurrentImage(paper.image);
                        setCurrentTitle(paper.title);
                        setOpen(true);
                      }}
                      sx={{ fontWeight: "bold" }}
                    >
                      View Image
                    </Button>
                    {paper.document && (
                      <Button
                        size="small"
                        color="primary"
                        href={paper.document}
                        target="_blank"
                        sx={{ fontWeight: "bold" }}
                      >
                        View Document
                      </Button>
                    )}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {currentTitle}
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              component="img"
              src={currentImage}
              alt="Full view"
              sx={{ width: "100%" }}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
}

export default Papers;

import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

function Projects() {
  const theme = useTheme(); // Access the current theme
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");

  const handleClickOpen = (image, title) => {
    setCurrentImage(image);
    setCurrentTitle(title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage("");
    setCurrentTitle("");
  };

  const projects = [
    {
      title: "Exploring Reinforcement Learning for MDPs",
      description:
        "Research paper comparing the performance of Policy Iteration, Value Iteration, Q-Learning, and SARSA in traffic flow and robot navigation Markov Decision Processes.",
      image: "/images/rl_mdp_preview.jpg",
      document: "/documents/rl_mdp_paper.pdf",
      link: null,
    },
    {
      title: "Web Development Certificate",
      description:
        "Build, deploy, and scale an E-Commerce app using Microservices built with Node, React, Docker, and Kubernetes. (54.5 hours)",
      image: "/images/web_dev_certificate.jpg",
      document: null,
      link: null,
    },
    {
      title: "Exploring Supervised Learning Techniques",
      description:
        "Research paper on supervised learning machine learning algorithms.",
      image: "/images/supervised_learning_preview.jpg",
      document: "/documents/supervised_learning_paper.pdf",
      link: null,
    },
    {
      title: "Programmed for Success",
      institution: "Ohio Wesleyan University",
      description:
        "Ohio Wesleyan Student Helps Manufacturer to Link Software Planning and Implementation Systems.",
      image: "/images/programmed_for_success_preview.jpg",
      document: "/documents/programmed_for_success.pdf",
      link: "https://www.owu.edu/news-media/details/programmed-for-success/",
    },
    {
      title: "Colonist.io Chrome Extension",
      description:
        "Counts cards using the game logs and circumvents the anti-adblocker.",
      image: "/images/colonist_extension_preview.jpg",
      document: null,
      link: "https://github.com/Elijah-Adams/colonist-extension",
      repository: "https://github.com/Elijah-Adams/colonist-extension",
    },
  ];

  return (
    <Box
      id="projects"
      sx={{
        py: 5,
        backgroundColor: theme.palette.background.default, // Dynamic background based on theme
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          gutterBottom
          align="left"
          sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
        >
          Projects
        </Typography>
        {projects.map((project, index) => (
          <Card
            key={index}
            sx={{
              mb: 3,
              boxShadow: 3, // Base shadow for elevation
              borderRadius: 2, // Rounded corners
              transition: "transform 0.3s, box-shadow 0.3s", // Smooth transition for hover effects
              backgroundColor: theme.palette.cardBackground, // Dynamic card background based on theme
              color: theme.palette.text.primary, // Text color from theme
              "&:hover": {
                boxShadow: 6, // Increased shadow on hover
                transform: "scale(1.02)", // Slight scaling effect on hover
              },
            }}
          >
            {project.image && (
              <Box sx={{ position: "relative", height: 200 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={project.image}
                  alt={`${project.title} preview`}
                  loading="lazy"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderBottom: `1px solid ${theme.palette.border.main}`, // Add border at the bottom matching the theme border
                  }}
                />
                {/* Dynamic Overlay Tint */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(0, 0, 0, 0.2)" // Lighter dark tint for dark mode
                        : "", // Light tint for light mode
                  }}
                />
              </Box>
            )}
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.text.primary,
                }}
              >
                {project.title}
              </Typography>
              {project.institution && (
                <Typography variant="subtitle1" color="text.secondary">
                  {project.institution}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  mt: 1,
                  color: theme.palette.text.primary,
                }}
              >
                {project.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  bottom: 16,
                  left: 12,
                }}
              >
                <Button
                  size="small"
                  color="info"
                  onClick={() => handleClickOpen(project.image, project.title)}
                  sx={{ mr: 1, fontWeight: "bold" }} // Make button text bold
                >
                  View Image
                </Button>
                {index === 3 && (
                  <Button
                    size="small"
                    color="primary"
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mr: 1, fontWeight: "bold" }} // Make button text bold
                  >
                    View Article
                  </Button>
                )}
                {project.link && index !== 3 && (
                  <Button
                    size="small"
                    color="primary"
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mr: 1, fontWeight: "bold" }} // Make button text bold
                  >
                    View Project
                  </Button>
                )}
                {project.document && index !== 3 && (
                  <Button
                    size="small"
                    color="primary"
                    href={project.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontWeight: "bold" }} // Make button text bold
                  >
                    View Document
                  </Button>
                )}
              </Box>
            </CardActions>
          </Card>
        ))}
      </Container>

      {/* Modal for Viewing Full Image */}
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          {currentTitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            component="img"
            src={currentImage}
            alt="Full project view"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: 1,
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Projects;

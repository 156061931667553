import { createTheme } from '@mui/material/styles';

const lightThemeColors = {
  primary: '#1e90ff',         // Green
  secondary: '#ffeb3b',       // Yellow
  backgroundDefault: '#f0f4f8', // Light Grayish Blue
  backgroundPaper: '#ffffff',  // White
  textPrimary: '#212121',      // Dark Gray
  textSecondary: '#757575',    // Medium Gray
  border: '#bdbdbd',           // Light Gray
  hoverBackground: '#e0e0e0',  // Light Gray for hover
  hoverText: '#000000',        // Black for hover text
  cardBackground: '#ffffff',    // White
  icon: '#4a4a4a',             // Dark Gray for icons
};

const darkThemeColors = {
  primary: '#1e90ff',
  secondary: '#ff4081',
  backgroundDefault: '#242424',
  backgroundPaper: '#1c1c1c',
  textPrimary: '#e0e0e0',
  textSecondary: '#1e90ff',
  border: '#3d3d3d',
  hoverBackground: '#333333',
  hoverText: '#ffffff',
  cardBackground: '#2d2d2d',
  icon: '#e0e0e0',
};

// Separate college colors
const collegeColors = {
  georgiaTech: '#877739',
  ohioWesleyan: '#C51230',
};

const getTheme = (mode) => {
  const colors = mode === 'dark' ? darkThemeColors : lightThemeColors;

  return createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      background: {
        default: colors.backgroundDefault,
        paper: colors.backgroundPaper,
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
      },
      grey: {
        700: colors.border,
      },
      border: {
        main: colors.border,
      },
      cardBackground: colors.cardBackground,
      customColors: {
        georgiaTech: collegeColors.georgiaTech,
        ohioWesleyan: collegeColors.ohioWesleyan,
        icon: colors.icon,
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.backgroundPaper,
            color: colors.textPrimary,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: colors.backgroundPaper,
            color: colors.textPrimary,
            borderRight: `1px solid ${colors.border}`,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: colors.hoverBackground,
              color: colors.hoverText,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          button: {
            textTransform: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: colors.cardBackground,
            color: colors.textPrimary,
          },
        },
      },
    },
  });
};

export default getTheme;

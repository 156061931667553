// src/components/About.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function About() {
  return (
    <Box id="about" sx={{ py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          About Me
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          Hello, I'm Elijah, an AI/ML Computer Scientist and software engineer who loves turning
          complex challenges into innovative solutions. With experience in defense technologies,
          AI/ML systems, and full-stack web development, I’ve had the opportunity to work on both
          classified and unclassified projects that push the limits of technology.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          At ZelTech, I focus on developing advanced AI/ML models and full-stack web applications.
          Using frameworks like TensorFlow, PyTorch, and Scikit-learn, I work on a wide range of
          projects, from computer vision and natural language processing to building scalable,
          user-friendly web solutions. Before this, I was at Northrop Grumman, where I helped develop
          safety-critical software and integrate it with hardware for defense applications. I also
          have experience building scalable systems using Docker, Kubernetes, AWS, and Red Hat
          OpenShift.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          I'm currently pursuing a Master’s in Machine Learning at Georgia Tech, where I’m diving
          into areas like machine learning, reinforcement learning, and deep learning. Prior to this,
          I earned a Bachelor’s degree in Computer Science and Mathematics from Ohio Wesleyan
          University.
        </Typography>
        <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
          When I’m not working on technical stuff, I’m spending time on my hobbies: technology 💻,
          investing 💰, weightlifting 🏋️‍♂️, making music 🎶, and going on adventures! 🌍
        </Typography>
      </Container>
    </Box>
  );
}

export default About;

// src/components/Sidebar.js
import {
  Work as BriefcaseIcon,
  Email as EmailIcon,
  GitHub as GitHubIcon,
  Link as LinkIcon,
  LinkedIn as LinkedInIcon,
  LocationOn as LocationIcon,
  School as SchoolIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import profile from "../assets/profile.jpg";
import { useState } from "react";

const drawerWidth = 320;

function Sidebar({
  open,
  handleSidebarToggle,
  isDesktop,
  navItems,
  externalRoutes,
}) {
  const theme = useTheme();
  const [activeItem, setActiveItem] = useState(null);

  const socialLinks = [
    {
      icon: <EmailIcon sx={{ color: theme.palette.customColors.icon }} />,
      label: "Email",
      href: "mailto:elijahthomasadams@gmail.com",
    },
    {
      icon: <LinkedInIcon sx={{ color: theme.palette.customColors.icon }} />,
      label: "LinkedIn",
      href: "https://linkedin.com/in/elijahthomasadams",
    },
    {
      icon: <GitHubIcon sx={{ color: theme.palette.customColors.icon }} />,
      label: "GitHub",
      href: "https://github.com/Elijah-Adams",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollTo = (to) => {
    scroller.scrollTo(to, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -70,
    });
  };

  const handleNavClick = (item) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        handleScrollTo(item.to);
      }, 300);
    } else {
      handleScrollTo(item.to);
    }
    if (!isDesktop) {
      handleSidebarToggle();
    }
  };

  const drawerContent = (
    <Box
      sx={{
        width: drawerWidth,
        p: 3,
        pb: 10,
        boxSizing: "border-box",
        height: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Avatar
          src={profile}
          alt="Profile Picture"
          sx={{ width: 150, height: 150, mx: "auto", mb: 2 }}
        />
        <Typography variant="h6" color={theme.palette.text.primary}>
          Elijah Adams
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          (He/Him)
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          AI/ML Computer Scientist
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <BriefcaseIcon
              fontSize="small"
              sx={{ mr: 1, color: theme.palette.customColors.icon }}
            />
            <Typography variant="body2">Zel Technologies LLC</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <SchoolIcon
              fontSize="small"
              sx={{ mr: 1, color: theme.palette.customColors.icon }}
            />
            <Typography variant="body2">
              Georgia Institute of Technology
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mb: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <LocationIcon
              fontSize="small"
              sx={{ mr: 1, color: theme.palette.customColors.icon }}
            />
            <Typography variant="body2">Melbourne, Florida</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: theme.palette.text.secondary,
            }}
          >
            <LinkIcon
              fontSize="small"
              sx={{ mr: 1, color: theme.palette.customColors.icon }}
            />
            <a
              href="https://www.elijahadams.me/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="body2"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                elijahadams.me
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {socialLinks.map((link, index) => (
          <Tooltip key={index} title={link.label} arrow>
            <IconButton
              component="a"
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              sx={{ mx: 1 }}
            >
              {React.cloneElement(link.icon, {
                sx: { color: theme.palette.customColors.icon },
              })}
            </IconButton>
          </Tooltip>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <List>
        {externalRoutes.map((route, index) => (
          <ListItem
            button
            key={index}
            onClick={() => {
              navigate(route.path);
              window.scrollTo(0, 0);
              setActiveItem(null);
              if (!isDesktop) {
                handleSidebarToggle();
              }
            }}
            sx={{
              "& .MuiListItemText-primary": {
                color:
                  location.pathname === route.path
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
              },
              "&:hover .MuiListItemText-primary": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <ListItemText primary={route.text} />
          </ListItem>
        ))}
      </List>

      <Divider sx={{ my: 2 }} />

      <List>
        {navItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component="div"
            onClick={() => {
              handleNavClick(item);
              setActiveItem(index);
            }}
            sx={{
              "& .MuiListItemText-primary": {
                color:
                  activeItem === index
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
              },
              "&:hover .MuiListItemText-primary": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      variant={isDesktop ? "persistent" : "temporary"}
      open={open}
      elevation={0}
      ModalProps={{
        keepMounted: true,
        hideBackdrop: true,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: "border-box",
          top: "64px",
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: "none",
          borderRight: `1px solid ${theme.palette.divider}`,
          ...(isDesktop && {
            position: "fixed",
          }),
          zIndex: theme.zIndex.drawer,
        },
      }}
      anchor="left"
    >
      {drawerContent}
    </Drawer>
  );
}

export default Sidebar;
import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Splash.css";

const roles = ["Developer", "Engineer", "Scientist"]; // Roles to type out

const MIN_WIDTH = 375; // Minimum screen width in pixels (mobile)
const MAX_WIDTH = 3840; // Maximum screen width in pixels (4K desktop)

function Splash({ isMobileView }) {
  const canvasRef = useRef(null);
  const theme = useTheme();
  const particles = useRef([]); // Use a ref to store particles
  const [displayText, setDisplayText] = useState(""); // Text currently displayed
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0); // Current role index
  const [isTyping, setIsTyping] = useState(true); // Typing or backspacing
  const [isCursorBlinking, setIsCursorBlinking] = useState(false); // Control cursor blinking
  const [scale, setScale] = useState(1); // Scaling factor based on screen width

  const typingSpeed = 150; // ms per character
  const backspacingSpeed = 100; // ms per character
  const pauseDuration = 1500; // ms to pause after typing a role
  const cursorBlinkDelay = 500; // ms delay before cursor starts blinking

  // Function to calculate scale based on current window width
  const calculateScale = () => {
    const width = window.innerWidth;
    const clampedWidth = Math.max(MIN_WIDTH, Math.min(width, MAX_WIDTH));
    const computedScale = (clampedWidth - MIN_WIDTH) / (MAX_WIDTH - MIN_WIDTH);
    return computedScale;
  };

  // Drawing function
  const draw = (connectionDistance, ctx) => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    particles.current.forEach((particle, index) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fillStyle =
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.7)"
          : "rgba(0, 0, 0, 0.7)";
      ctx.fill();
      ctx.closePath();

      particle.x += particle.dx;
      particle.y += particle.dy;

      // Wrap particles around the edges
      if (particle.x > canvasRef.current.width) particle.x = 0;
      if (particle.x < 0) particle.x = canvasRef.current.width;
      if (particle.y > canvasRef.current.height) particle.y = 0;
      if (particle.y < 0) particle.y = canvasRef.current.height;
    });

    // Draw connections after updating positions
    particles.current.forEach((particle, index) => {
      for (let j = index + 1; j < particles.current.length; j++) {
        const dx = particles.current[j].x - particle.x;
        const dy = particles.current[j].y - particle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < connectionDistance) {
          ctx.strokeStyle =
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(0, 0, 0, 0.3)";
          ctx.lineWidth = 0.5;
          ctx.beginPath();
          ctx.moveTo(particle.x, particle.y);
          ctx.lineTo(particles.current[j].x, particles.current[j].y);
          ctx.stroke();
          ctx.closePath();
        }
      }
    });
  };

  // Initialize or update particles based on the current scale
  const initializeOrUpdateParticles = (canvas, currentScale) => {
    const minParticles = 100;
    const maxParticles = 300;
    const numParticles = Math.floor(
      minParticles + currentScale * (maxParticles - minParticles)
    );

    const minRadius = 0.5;
    const maxRadius = 2;
    const radius = minRadius + currentScale * (maxRadius - minRadius);

    const minSpeed = 0.25;
    const maxSpeed = 1;
    const speed = minSpeed + currentScale * (maxSpeed - minSpeed);

    const connectionDistanceMin = 25;
    const connectionDistanceMax = 100;
    const connectionDistance =
      connectionDistanceMin +
      currentScale * (connectionDistanceMax - connectionDistanceMin);

    // Adjust the number of particles
    const currentNum = particles.current.length;
    if (currentNum < numParticles) {
      for (let i = currentNum; i < numParticles; i++) {
        particles.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: radius * (0.5 + Math.random()), // Slight variation
          dx: (Math.random() * 2 - 1) * speed,
          dy: (Math.random() * 2 - 1) * speed,
        });
      }
    } else if (currentNum > numParticles) {
      particles.current.splice(numParticles, currentNum - numParticles);
    }

    // Update existing particles' properties
    particles.current.forEach((particle) => {
      particle.radius = radius * (0.5 + Math.random());
      particle.dx = (Math.random() * 2 - 1) * speed;
      particle.dy = (Math.random() * 2 - 1) * speed;
    });

    return connectionDistance;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Initial scale calculation
    let currentScale = calculateScale();
    setScale(currentScale);

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      currentScale = calculateScale();
      setScale(currentScale);
      const connectionDistance = initializeOrUpdateParticles(
        canvas,
        currentScale
      );
      draw(connectionDistance, ctx); // Pass the context to draw
    };

    // Initialize canvas size and particles
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    // Render loop
    let animationFrameId;
    const render = () => {
      const connectionDistance = 25 + scale * (100 - 25);
      draw(connectionDistance, ctx);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [theme, scale]);

  useEffect(() => {
    let timeoutId1;
    let timeoutId2;

    const currentRole = roles[currentRoleIndex];

    if (isTyping) {
      if (displayText.length === 0 && isCursorBlinking) {
        setIsCursorBlinking(false);
      }
      if (displayText.length < currentRole.length) {
        timeoutId1 = setTimeout(() => {
          setDisplayText(currentRole.slice(0, displayText.length + 1));
        }, typingSpeed);
      } else {
        timeoutId1 = setTimeout(() => {
          setIsCursorBlinking(true);
        }, cursorBlinkDelay);
        timeoutId2 = setTimeout(() => {
          setIsTyping(false);
        }, cursorBlinkDelay + pauseDuration);
      }
    } else {
      if (displayText.length === currentRole.length && isCursorBlinking) {
        setIsCursorBlinking(false);
      }
      if (displayText.length > 0) {
        timeoutId1 = setTimeout(() => {
          setDisplayText(currentRole.slice(0, displayText.length - 1));
        }, backspacingSpeed);
      } else {
        timeoutId1 = setTimeout(() => {
          setIsCursorBlinking(true);
        }, cursorBlinkDelay);
        timeoutId2 = setTimeout(() => {
          setCurrentRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
          setIsTyping(true);
        }, cursorBlinkDelay + pauseDuration);
      }
    }

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [displayText, isTyping, currentRoleIndex, isCursorBlinking]);

  return (
    <Box
      id="splash"
      margin="-25px"
      sx={{
        position: "relative",
        minHeight: "calc(100vh - 64px)", // Adjusted height for header and margin
        overflow: "hidden",
        color: theme.palette.text.primary,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      ></canvas>

      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          px: 2,
        }}
      >
        <Typography
          gutterBottom
          sx={{
            width: isMobileView ? "375px" : "100%",
            color: theme.palette.text.primary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            whiteSpace: "pre-wrap", // Allow text to wrap naturally
            textAlign: "center",
            fontSize: isMobileView ? "2rem" : "6vw", // Use rem for mobile, vw for desktop
          }}
        >
          {`${displayText}`}
          <span
            className={`cursor ${isCursorBlinking ? "cursor--blinking" : ""}`}
            style={{
              verticalAlign: "baseline",
              marginLeft: "0.5vw",
              display: "inline-block", // Ensure it behaves like an inline element
              width: "2px", // Set width for the cursor
              height: isMobileView ? "2.8rem" : "7vw", // Set height based on device view
              backgroundColor: "currentColor", // Use the current text color for consistency
              transition: "opacity 0.5s ease-in-out", // Smooth transition for blinking
              animation: isCursorBlinking
                ? "blink 1s step-end infinite"
                : "none", // Blinking effect
            }}
          />
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 2,
        }}
      >
        <ArrowDownwardIcon
          sx={{
            fontSize: 40,
            animation: "bounce 1.5s infinite",
            cursor: "pointer",
          }}
          onClick={() =>
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }
        />
      </Box>
    </Box>
  );
}

export default Splash;

// src/App.js
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import GlobalStylesComponent from "./GlobalStyles";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MiniMap from "./components/MiniMap";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Research from "./pages/Research";
import getTheme from "./theme";
import ErrorBoundary from "./ErrorBoundary";

function AppContent({
  darkMode,
  isMiniMapOpen,
  navItems,
  onMiniMapRendered,
  ...props
}) {
  const location = useLocation();
  const [currentElementId, setCurrentElementId] = useState("home");

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrentElementId("home");
    } else if (location.pathname === "/research") {
      setCurrentElementId("research");
    }
  }, [location]);

  useEffect(() => {
    if (isMiniMapOpen) {
      // Signal that MiniMap is rendered
      onMiniMapRendered();
    }
  }, [isMiniMapOpen, onMiniMapRendered]);

  return (
    <ErrorBoundary>
      {isMiniMapOpen && (
        <MiniMap
          elementID={currentElementId}
          darkMode={darkMode}
          navItems={navItems}
        />
      )}
      <Box
        id="main-content"
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Routes>
          <Route path="/" element={<Home id="home" {...props} />} />
          <Route
            path="/research"
            element={<Research id="research" {...props} />}
          />
        </Routes>
      </Box>
    </ErrorBoundary>
  );
}

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMiniMapOpen, setIsMiniMapOpen] = useState(true);
  const [miniMapLoaded, setMiniMapLoaded] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const theme = useMemo(
    () => getTheme(darkMode ? "dark" : "light"),
    [darkMode]
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const drawerWidth = 320;

  const navItems = [
    { text: "Splash", to: "splash" },
    { text: "About", to: "about" },
    { text: "Experience", to: "experience" },
    { text: "Education", to: "education" },
    { text: "Projects", to: "projects" },
    { text: "Contact", to: "contact" },
  ];

  const externalRoutes = [
    { text: "Home", path: "/" },
    { text: "Research", path: "/research" },
  ];

  useEffect(() => {
    if (isDesktop) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [isDesktop]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const toggleMobileView = () => {
    setIsMobileView((prev) => !prev);
  };

  const toggleMiniMap = () => {
    setIsMiniMapOpen((prev) => !prev);
    if (!isMiniMapOpen) {
      setMiniMapLoaded(false);
    }
  };

  const handleMiniMapRendered = () => {
    setMiniMapLoaded(true);
  };

  const closeAll = (event) => {
    const isClickInsideDrawerOrButton = event.target.closest('.MuiDrawer-paper, .MuiIconButton-root');
    if (!isClickInsideDrawerOrButton) {
      setIsSidebarOpen(false);
      setIsSettingsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeAll);
    return () => {
      document.removeEventListener('click', closeAll);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStylesComponent />
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode}
            handleSidebarToggle={handleSidebarToggle}
            isDesktop={isDesktop}
            isMobileView={isMobileView}
            toggleMobileView={toggleMobileView}
            toggleMiniMap={toggleMiniMap}
            isMiniMapOpen={isMiniMapOpen}
            onMiniMapLoaded={miniMapLoaded}
            isSettingsOpen={isSettingsOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            setIsSettingsOpen={setIsSettingsOpen}
          />
          <Sidebar
            open={isSidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            isDesktop={isDesktop}
            isMobileView={isMobileView}
            navItems={navItems}
            externalRoutes={externalRoutes}
          />
          <AppContent
            darkMode={darkMode}
            isMiniMapOpen={isMiniMapOpen}
            navItems={navItems}
            isDesktop={isDesktop}
            isSidebarOpen={isSidebarOpen}
            isMobileView={isMobileView}
            drawerWidth={drawerWidth}
            theme={theme}
            onMiniMapRendered={handleMiniMapRendered}
          />
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
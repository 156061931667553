// src/components/MiniMap.js
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import html2canvas from "html2canvas";
import debounce from "lodash.debounce";

const MiniMap = ({ elementID, darkMode, isVisible = true }) => {
  const theme = useTheme();
  const minimapRef = useRef(null);
  const viewportIndicatorRef = useRef(null);
  const imageRef = useRef(null);
  const MINIMAP_WIDTH = 200;
  const HEADER_HEIGHT = 0;
  const FOOTER_HEIGHT = 64;
  const MOBILE_BREAKPOINT = 768;

  // Initialize states with try-catch to handle potential window undefined errors
  const [isDragging, setIsDragging] = useState(false);
  const [contentHeight, setContentHeight] = useState(() => {
    try {
      return document.documentElement.scrollHeight - window.innerHeight;
    } catch (e) {
      return 0;
    }
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [minimapWidth, setMinimapWidth] = useState(MINIMAP_WIDTH);
  const [imageAspectRatio, setImageAspectRatio] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [lastTouchY, setLastTouchY] = useState(null);

  const isMobile = useCallback(() => {
    try {
      return window.innerWidth <= MOBILE_BREAKPOINT;
    } catch (e) {
      return false;
    }
  }, []);

  const calculateOptimalWidth = useCallback((aspectRatio) => {
    if (!aspectRatio) return MINIMAP_WIDTH;
    try {
      const isMobileDevice = window.innerWidth <= MOBILE_BREAKPOINT;
      const maxHeight = window.innerHeight * (isMobileDevice ? 0.6 : 0.8);
      const defaultWidth = isMobileDevice ? MINIMAP_WIDTH * 0.7 : MINIMAP_WIDTH;
      const heightAtDefaultWidth = defaultWidth / aspectRatio;

      if (heightAtDefaultWidth > maxHeight) {
        return maxHeight * aspectRatio;
      }
      return defaultWidth;
    } catch (e) {
      return MINIMAP_WIDTH;
    }
  }, []);

  const updatePreview = useCallback(
    debounce(async () => {
      try {
        const targetElement = document.getElementById(elementID);
        if (!targetElement) return;

        const scale = isMobile() ? 0.5 : 1;
        const canvas = await html2canvas(targetElement, {
          scale,
          useCORS: true,
          allowTaint: true,
          logging: false,
          removeContainer: true,
          backgroundColor: theme.palette.background.default,
        });

        const fullCanvas = document.createElement("canvas");
        const ctx = fullCanvas.getContext("2d");

        fullCanvas.width = canvas.width;
        fullCanvas.height = canvas.height + HEADER_HEIGHT + FOOTER_HEIGHT;

        // Draw header background
        ctx.fillStyle = theme.palette.background.default;
        ctx.fillRect(0, 0, fullCanvas.width, HEADER_HEIGHT);

        // Draw main content
        ctx.drawImage(canvas, 0, HEADER_HEIGHT);

        // Draw footer background
        ctx.fillStyle = theme.palette.background.paper;
        ctx.fillRect(
          0,
          HEADER_HEIGHT + canvas.height,
          fullCanvas.width,
          FOOTER_HEIGHT
        );

        const aspectRatio = fullCanvas.width / fullCanvas.height;
        const optimalWidth = calculateOptimalWidth(aspectRatio);

        setPreviewImage(fullCanvas.toDataURL("image/jpeg", 0.7));
        setImageAspectRatio(aspectRatio);
        setMinimapWidth(optimalWidth);
        setIsInitialized(true);
      } catch (err) {
        console.error("Failed to capture preview:", err);
      }
    }, 300),
    [calculateOptimalWidth, theme.palette.background, elementID, isMobile]
  );

  const handleResize = useCallback(
    debounce(() => {
      const newContentHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setContentHeight(newContentHeight);
      const optimalWidth = calculateOptimalWidth(imageAspectRatio);
      setMinimapWidth(optimalWidth);
    }, 300),
    [calculateOptimalWidth, imageAspectRatio]
  );

  const updateIndicator = useCallback(() => {
    if (!viewportIndicatorRef.current || !minimapRef.current) return;

    const scrollTop = window.pageYOffset;
    const viewportHeight = window.innerHeight - (HEADER_HEIGHT + FOOTER_HEIGHT);
    const minimapHeight = minimapRef.current.offsetHeight;

    const indicatorHeight =
      (viewportHeight / (contentHeight + viewportHeight)) * minimapHeight;
    const scrollRatio = contentHeight ? scrollTop / contentHeight : 0;
    const indicatorTop = scrollRatio * (minimapHeight - indicatorHeight);

    viewportIndicatorRef.current.style.height = `${indicatorHeight}px`;
    viewportIndicatorRef.current.style.transform = `translateY(${Math.max(
      0,
      Math.min(indicatorTop, minimapHeight - indicatorHeight)
    )}px)`;
  }, [contentHeight]);

  const scrollToPosition = useCallback(
    (clientY, isTouch = false) => {
      if (!minimapRef.current || !viewportIndicatorRef.current) return;

      const minimapRect = minimapRef.current.getBoundingClientRect();
      const minimapHeight = minimapRef.current.offsetHeight;
      const indicatorHeight = viewportIndicatorRef.current.offsetHeight;

      // Add smooth interpolation for touch events
      const clickY = clientY - minimapRect.top - (isTouch ? 0 : indicatorHeight / 2);
      const boundedClickY = Math.max(
        0,
        Math.min(clickY, minimapHeight - indicatorHeight)
      );
      
      const scrollRatio = boundedClickY / (minimapHeight - indicatorHeight);
      const targetScroll = Math.max(
        0,
        Math.min(
          scrollRatio * contentHeight,
          document.documentElement.scrollHeight - window.innerHeight
        )
      );

      // Use requestAnimationFrame for smoother scrolling on touch
      if (isTouch) {
        requestAnimationFrame(() => {
          window.scrollTo({
            top: targetScroll + HEADER_HEIGHT,
            behavior: "auto"
          });
        });
      } else {
        window.scrollTo({
          top: targetScroll + HEADER_HEIGHT,
          behavior: "auto"
        });
      }
    },
    [contentHeight]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (!isMobile()) {
        e.preventDefault();
      }
      setIsDragging(true);
      scrollToPosition(e.clientY);
    },
    [scrollToPosition, isMobile]
  );

  const handleTouchStart = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setLastTouchY(e.touches[0].clientY);
      scrollToPosition(e.touches[0].clientY, true);
    },
    [scrollToPosition]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        if (isMobile()) {
          e.preventDefault();
          e.stopPropagation();
        }
        scrollToPosition(e.clientY);
      }
    },
    [isDragging, scrollToPosition, isMobile]
  );

  const handleTouchMove = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        
        const currentTouchY = e.touches[0].clientY;
        // Only update if touch position changed significantly
        if (Math.abs(currentTouchY - lastTouchY) > 1) {
          setLastTouchY(currentTouchY);
          scrollToPosition(currentTouchY, true);
        }
      }
    },
    [isDragging, scrollToPosition, lastTouchY]
  );

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    updatePreview();
    handleResize();
    updateIndicator();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", updateIndicator);

    let resizeObserver = null;
    const targetElement = document.getElementById(elementID);
    if (targetElement) {
      resizeObserver = new ResizeObserver(() => {
        updatePreview();
      });
      resizeObserver.observe(targetElement);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", updateIndicator);
      handleResize.cancel();
      updatePreview.cancel();
      if (resizeObserver && targetElement) {
        resizeObserver.unobserve(targetElement);
      }
    };
  }, [handleResize, updatePreview, updateIndicator, elementID]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      document.addEventListener("touchend", handleDragEnd);
      document.addEventListener("touchcancel", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleDragEnd);
      document.removeEventListener("touchcancel", handleDragEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleDragEnd);
      document.removeEventListener("touchcancel", handleDragEnd);
    };
  }, [isDragging, handleMouseMove, handleTouchMove, handleDragEnd]);

  useEffect(() => {
    if (previewImage) {
      const newContentHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setContentHeight(newContentHeight);
      updateIndicator();
    }
  }, [previewImage, updateIndicator]);

  // Initialize component
  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        if (typeof window !== "undefined" && mounted) {
          await updatePreview();
          handleResize();
          updateIndicator();
        }
      } catch (e) {
        console.error("Initialization error:", e);
      }
    };

    init();

    return () => {
      mounted = false;
      handleResize.cancel();
      updatePreview.cancel();
    };
  }, []);

  // Only render when properly initialized
  if (!isInitialized) return null;

  return (
    <Box
      ref={minimapRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      sx={{
        position: "fixed",
        top: "50%",
        right: isMobile() ? 10 : 20,
        transform: "translateY(-50%)",
        width: minimapWidth,
        height: "auto",
        maxHeight: isMobile() ? "60vh" : "80vh",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        overflow: "hidden",
        boxShadow: 3,
        zIndex: 1000,
        cursor: "pointer",
        visibility: isVisible && previewImage ? "visible" : "hidden",
        opacity: isVisible && previewImage ? 1 : 0,
        transition: "visibility 0.3s, opacity 0.3s, width 0.3s, height 0.3s",
        display: isVisible && previewImage ? "block" : "none",
        touchAction: "none",
        WebkitOverflowScrolling: "touch", // Add smooth scrolling for iOS
        "-webkit-user-select": "none",    // Prevent selection on touch
      }}
    >
      {previewImage && (
        <>
          <img
            ref={imageRef}
            src={previewImage}
            alt="Preview"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              pointerEvents: "none",
              userSelect: "none",
              WebkitUserDrag: "none",
              msUserSelect: "none",
              MozUserSelect: "none",
              transition: "width 0.3s, height 0.3s",
            }}
          />
          <Box
            ref={viewportIndicatorRef}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              backgroundColor: "rgba(100, 149, 237, 0.2)",
              boxSizing: "border-box",
              pointerEvents: "none",
              transition: isDragging ? "none" : "height 0.3s, transform 0.3s",
            }}
          />
        </>
      )}
    </Box>
  );
};

export default MiniMap;

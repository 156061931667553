import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  useTheme,
} from '@mui/material';

function ExperienceTitle() {
  const theme = useTheme();
  return (
    <Typography
      variant="h4"
      gutterBottom
      sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
    >
      Experience
    </Typography>
  );
}

function Experience() {
  const theme = useTheme();

  const experiences = [
    {
      title: 'AI/ML Computer Scientist',
      company: 'Zel Technologies LLC',
      duration: 'August 2024 – Present',
      location: 'Cape Canaveral, FL',
      description: [
        'Expertise in AI, ML, neural networks, and deep learning with TensorFlow, PyTorch, Scikit-learn, Keras, and Theano.',
        'Skilled in computer vision and NLP algorithms, with experience in OpenCV, TensorFlow-CV, NLTK, Spacy, and PyTorch-NLP.',
        'Extensive experience in DevSecOps, Docker, Kubernetes, Red Hat OpenShift, AWS, and databases (PostgreSQL, NoSQL, SQL).',
        'Strong in data preparation, pre-processing, QA/QC, GitLab, Jenkins, and software development in classified environments.',
        'Proficient in Linux, shell scripting, and programming in JavaScript, Node.js, TypeScript, Python, Java, C/C++. Strong web development skills using HTML, CSS, React, and Angular.',
      ],
    },
    {
      title: 'Software Engineer I',
      company: 'Northrop Grumman Corporation',
      duration: 'May 2023 – August 2024',
      location: 'Melbourne, FL',
      description: [
        'Leveraged web development knowledge to create a web app scheduler for shared hardware devices among 100+ engineers, reducing conflicts and delays in testing and development.',
        'Architected and implemented 3 large features, including encrypted communications between embedded devices and button processing functionalities; successfully demonstrated these to the customer.',
        'Consistently recognized in 4 consecutive quarterly retrospectives for excellence in hardware integration testing, process improvement, teammate and intern support, and top performance.',
        'Developed and maintained oneClick script using PyDev, Bash, and C++, automating the build process for five emulators and reducing start-up time by 50% with MIL-STD-1553 data bus system.',
        'Authored 4 Confluence pages documenting key development processes: Bamboo Wiki, Dual Boot Debugging for SRS Tests and CSCs, Alpha Team Build Wiki, and Atlassian Dark Mode (Classified).',
        'Led and facilitated effective cross-disciplinary communication, design meetings, and Agile Scrum practices using the Atlassian toolset in a Linux VMWare environment.',
      ],
    },
    {
      title: 'Software Engineer Intern',
      company: 'Custom Rubber Corporation',
      duration: 'May 2022 – August 2022',
      location: 'Cleveland, OH',
      description: [
        'Migrated over 50,000 records from a custom-built relational database to Infor\'s three-tier architecture on Microsoft SQL Server, enhancing coworkers\' ERP training with real data.',
        'Customized 10 Mongoose forms and reports in Infor SyteLine ERP to streamline core business processes and analyze Prophecy.IoT\'s integrated factory production data.',
        'Collaborated across disciplines using Kanban and Microsoft Teams, meeting deadlines 20% ahead of schedule.',
      ],
    },
  ];

  return (
    <Box
      id="experience"
      sx={{
        py: 3,
        pt: 9,
        borderRadius: 2,
        maxWidth: '1000px',
        margin: 'auto',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="md">
        <ExperienceTitle />
        {experiences.map((exp, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            {/* Header Section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                mb: 0.5,
              }}
            >
              {/* Left Side: Title and Company */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                >
                  {exp.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {exp.company}
                </Typography>
              </Box>

              {/* Right Side: Duration and Location */}
              <Box
                sx={{
                  textAlign: { xs: 'left', sm: 'right' },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {exp.duration}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {exp.location}
                </Typography>
              </Box>
            </Box>

            {/* Divider */}
            <Divider sx={{ my: 2 }} />

            {/* Description List */}
            <List dense>
              {exp.description.map((item, idx) => (
                <ListItem key={idx} sx={{ pl: 0 }}>
                  <ListItemText
                    primary={`• ${item}`}
                    primaryTypographyProps={{
                      variant: 'body1',
                      sx: {
                        color: theme.palette.text.primary,
                        lineHeight: 1.6,
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}

        {/* Resume Download Button */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="outlined" // Subtle look
            color="primary"
            href="/documents/Elijah_Adams_Resume.pdf" // Update with the correct path to your PDF
            download
          >
            Download Resume
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Experience;

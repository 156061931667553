// src/components/Header.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Drawer,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import {
  WbSunny as WbSunnyIcon,
  DarkMode as DarkModeIcon,
  Menu as MenuIcon,
  Map as MapIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function Header({
  darkMode,
  toggleDarkMode,
  handleSidebarToggle,
  isDesktop,
  toggleMiniMap,
  isMiniMapOpen,
  isSettingsOpen,
  setIsSettingsOpen,
}) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleMiniMapToggle = () => {
    if (!isMiniMapOpen) {
      setIsLoading(true);
      toggleMiniMap();
      // Simulate loading completion
      setTimeout(() => {
        setIsLoading(false);
        // Close the settings drawer after loading finishes
        setIsSettingsOpen(false);
      }, 1000); // Adjust the timeout as needed
    } else {
      toggleMiniMap();
      // Close the settings drawer immediately
      setIsSettingsOpen(false);
    }
  };

  const handleThemeToggle = () => {
    toggleDarkMode();
    // Close the settings drawer after toggling the theme
    setIsSettingsOpen(false);
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <AppBar
      id="header"
      position="fixed"
      elevation={0}
      sx={{
        width: "100%",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        backdropFilter: "blur(5px)",
        bgcolor: alpha(theme.palette.background.paper, 0.8),
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          minHeight: "64px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Open Menu">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
              className="drawer-trigger"
            >
              <MenuIcon sx={{ color: theme.palette.customColors.icon }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.text.primary,
                textAlign: "center",
                fontFamily: "Birthstone, sans-serif",
              }}
            >
              Elijah Adams
            </Typography>
          </Link>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isDesktop ? (
            <>
              <Tooltip title={darkMode ? "Dark Mode" : "Light Mode"}>
                <IconButton
                  onClick={toggleDarkMode}
                  color="inherit"
                  aria-label="toggle dark/light mode"
                  sx={{ mr: 1 }}
                  className="drawer-trigger"
                >
                  {darkMode ? (
                    <DarkModeIcon sx={{ color: theme.palette.customColors.icon }} />
                  ) : (
                    <WbSunnyIcon sx={{ color: theme.palette.customColors.icon }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={isMiniMapOpen ? "Hide MiniMap" : "Show MiniMap"}>
                <IconButton
                  onClick={handleMiniMapToggle}
                  color="inherit"
                  aria-label="toggle minimap"
                  className="drawer-trigger"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: theme.palette.customColors.icon }}
                    />
                  ) : (
                    <MapIcon sx={{ color: theme.palette.customColors.icon }} />
                  )}
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Settings">
                <IconButton
                  onClick={toggleSettings}
                  color="inherit"
                  aria-label="settings"
                  className="drawer-trigger"
                >
                  <SettingsIcon sx={{ color: theme.palette.customColors.icon }} />
                </IconButton>
              </Tooltip>
              <Drawer
                anchor="right"
                open={isSettingsOpen}
                variant="temporary"
                ModalProps={{
                  keepMounted: true,
                  hideBackdrop: true,
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: 71,
                    top: '64px',
                    height: 'auto',
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderRight: 'none',
                    boxShadow: 'none',
                    zIndex: theme.zIndex.drawer + 2,
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                    pointerEvents: 'auto',
                  }
                }}
              >
                <List sx={{ p: 0 }}>
                  <ListItem 
                    sx={{ 
                      justifyContent: 'center', 
                      py: 1.5,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      },
                    }}
                  >
                    <IconButton onClick={handleThemeToggle} className="drawer-trigger">
                      {darkMode ? (
                        <DarkModeIcon sx={{ color: theme.palette.customColors.icon }} />
                      ) : (
                        <WbSunnyIcon sx={{ color: theme.palette.customColors.icon }} />
                      )}
                    </IconButton>
                  </ListItem>
                  <ListItem 
                    sx={{ 
                      justifyContent: 'center', 
                      py: 1.5,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      },
                    }}
                  >
                    <IconButton onClick={handleMiniMapToggle} className="drawer-trigger">
                      {isLoading ? (
                        <CircularProgress
                          size={24}
                          sx={{ color: theme.palette.customColors.icon }}
                        />
                      ) : (
                        <MapIcon sx={{ color: theme.palette.customColors.icon }} />
                      )}
                    </IconButton>
                  </ListItem>
                </List>
              </Drawer>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
import { Box } from "@mui/material";
import React from "react";
import Papers from "../components/Papers";

function Research({
  isDesktop,
  isSidebarOpen,
  isMobileView,
  drawerWidth,
  theme,
}) {
  return (
    <Box
      id="research"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        color: "text.primary",
        p: 3,
        mt: 5,
        ml:
          isDesktop && isSidebarOpen && !isMobileView ? `${drawerWidth}px` : 0,
      }}
    >
      <Papers />
    </Box>
  );
}

export default Research;
